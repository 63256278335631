var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page404" },
    [
      _vm._m(0),
      _c("div", { staticClass: "page404__description" }, [
        _vm._v("Упс, что то пошло не так..."),
      ]),
      _vm._m(1),
      _c("MainButton", {
        staticClass: "page404__button",
        attrs: { title: "Вернуться на главную" },
        on: {
          "button-clicked": function ($event) {
            return _vm.$router.push("/terminal")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page404__title" }, [
      _vm._v("4"),
      _c("span", [_vm._v("0")]),
      _vm._v("4"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page404__help" }, [
      _vm._v(" Попробуйте повторить свои действия."),
      _c("br"),
      _vm._v(" Если сообщение об ошибке будет повторяться,"),
      _c("br"),
      _vm._v(" обратитесь в тех.поддержку "),
      _c("span", [_vm._v("8 800 250 75 31")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }