<template>
  <div class="page404">
    <div class="page404__title">4<span>0</span>4</div>
    <div class="page404__description">Упс, что то пошло не так...</div>
    <div class="page404__help">
      Попробуйте повторить свои действия.<br>
      Если сообщение об ошибке будет повторяться,<br>
      обратитесь в тех.поддержку <span>8 800 250 75 31</span>
    </div>
    <MainButton
        title="Вернуться на главную"
        class="page404__button"
        @button-clicked="$router.push('/terminal')"
    />
  </div>
</template>

<script>
import MainButton from '@/views/terminalUI/components/UI/buttons/MainButton'
export default {
  name: 'EarlyArrived',
  components: { MainButton },
}
</script>

<style lang="sass" scoped>
.page404
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 120px
  font-family: $fontPrimary
  &__title
    margin-bottom: 40px
    font-size: 240px
    font-weight: 700
    line-height: 266px
    color: $mainBlack
    span
      color: $special
  &__description
    margin-bottom: 32px
    font-size: 40px
    line-height: 48px
    font-weight: 500
    color: $mainBlack
  &__help
    width: 580px
    margin-bottom: 50px
    text-align: center
    color: $greyText
    font-size: 20px
    font-weight: 500
    line-height: 28px
    span
      color: $special
  &__button
    width: 540px
    ::v-deep &.el-button--primary.is-plain
      height: 66px
      font-size: 20px
</style>
